* {
  margin : 0;
  font-family: 'Lato', sans-serif;
}

.app {
  background-color: #FAFAFA
}

.app__posts {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.app__postsRight {
  margin-left: 20px
}

.app__header {
  border-bottom: 1px solid lightgray;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
}

.app__header-wrapper {
 /*  width: 800px; */
  text-align: center;
  display: flex;
  justify-content: center;
}

.app__header-logo {
  font-family: 'Dancing Script', cursive;
  font-size: 1.5rem;
}

.app__signup {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.app__imageupload {
  /* position: sticky;
  bottom: 0; */
  background-color: white;
  /* padding-top: 5px;
  padding-bottom: 5px; */
  text-align: center;
  border-bottom: 1px solid lightgray;

}

.app__modal {
  width: 100%;
}
