.post {
    max-width: 500px;
    background-color: white;
    border: 1px solid lightgray;
    margin-bottom: 45px;
}

.post__header{
    display: flex;
    align-items: center;
    padding: 20px;
}

.post__avatar {
    margin-right: 10px;
}

.post__image {
    width:100%;
    object-fit: contain;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray
}

.post__text {
    font-weight: normal;
    padding: 20px;
}

.post__commentBox {
    display: flex;
    margin-top: 10px;
}

.post__input {
    flex: 1;
    border: none;
    padding: 10px;
    border-top: 1px solid lightgray;
}

.post__button {
    flex: 0;
    border: none;
    background-color:#0095F6;
    border-top: 1px solid lightgray;
    color: white;
}

.post__comments {
    padding: 20px
}