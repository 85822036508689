.imageupload {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    margin-bottom: 0px; 
    /* background-color: white; */
    /*border-top: 1px solid lightgrey; */
    /* position: sticky;
    bottom: 0; */
}

.imageupload__progress {
    width: 100%;
}